.solTextInput {
    /* remove standard background appearance */
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;
    /* create custom text input appearance */
    display: inline-block !important;
    width: 180px;
    height: 35px;
    border: 1px solid #bbbbbb !important;
    border-radius: 3px !important;
    /* styles from form-control class */
    padding: 0.65rem 1rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #3F4254;
    background-color: #ffffff;
    background-clip: padding-box;
    box-shadow: none;
    transition: border-color 0.15s ease-in-out;
}

.solTextInput:focus {
    outline: 0 !important;
    border: 1px solid #F18D00 !important;
    box-shadow: 0 0 3px 1px #F18D00 !important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}