@import url('https://fonts.googleapis.com/css2?family=Roboto:ital@1&display=swap');

.replacement-form-container {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(3, 1fr);
  padding-bottom: 20px ;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Roboto', sans-serif;
}

.expand-column {
  grid-column: 2/-1;
  font-weight: 700;
}

.shrink-column {
  grid-column: 1/2;
  font-weight: 700;
}

.label-padding {
  display: block;
  width: 100%;
  height: calc(1.5em + 1.3rem + 2px);
  padding: 0.65rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  //border: 1px solid #e2e5ec;
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin: 0;
  font-family: inherit;
}


.border-to-right {
  border-bottom: none;
  border-top: none;
  border-left: none;
  border-right: .25em dimgrey solid;
}

.border-to-left {
  border-bottom: none;
  border-top: none;
  border-left: none;
  border-left: .25em dimgrey solid;
}

.padding-to-left {
  padding-left: 40px !important;
}
.padding-to-right {
  padding-right: 40px !important;
}
.padding-to-bottom {
  padding-bottom: 30px !important;
}


.button-row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);


  &__end-of-line {
    grid-column: 3/-1;
    text-align: right;
    padding-bottom: 15px;
  }
}


.step-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0;
  padding-bottom: 40px;
  column-gap: 1%;
  font-family: 'Roboto', sans-serif;
}

.step-circle {
  background-color: #273c75;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  color: #ffffff;
  display: grid;
  place-items: center;
  grid: 1/2;
}

.step-circle__label {
  color: #ffffff;
  font-size: 2rem;
  font-weight: 500;
}

.additional-title {
  font-size: 2rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  grid-column: 1/-1;
  margin: auto 0;
}

.step-title {
  font-size: 2rem;
  grid-column: 2/-1;
  margin: auto -39%;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
}

@media only screen and (max-width: 1500px) {
  .step-title {
    margin: auto -33%;
  }
}

@media only screen and (max-width: 1600px) {
  .step-title {
    margin: auto -36%;
  }
}

@media only screen and (max-width: 1366px) {
  .step-title {
    margin: auto -32%;
  }

  .replacement-form-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

//@media screen and (max-width: 1600px) {
//  .step-title {
//    margin: auto -28%;
//  }
//}
//
//@media screen and (max-width: 1800px) {
//  .step-title {
//    margin: auto -27%;
//  }
//
//}

//@media screen and (max-width: 1920px) {
//  .step-title {
//    margin: auto -33%;
//  }
//}


//.ul-error-case:before {
//  background-image: url('../../../../utils/asset/tick.png');
//  margin: 0 auto;
//  padding-right: 20px;
//}
//.ul-success-case:before {
//  content: url('../../../../utils/asset/tick.png');
//  margin: 0 auto;
//  padding-right: 20px;
//}
//ul .ul-success-case{
//  list-style-image: url('../../../../utils/asset/tick.png');
//}
//ul .ul-error-case{
//  list-style-image: url('../../../../utils/asset/criss-cross.png');
//}

.ul-error-case {
  color: #c0392b;
  font-size: 1.5em;
  background: url('../../utils/asset/criss-cross.png') no-repeat left top;
  list-style: none;
  padding: 0 0 10px 35px;
  margin: 0;
  font-weight: 500;
}

.ul-success-case {
  color: #0abb87;
  font-size: 1.5em;
  background: url('../../utils/asset/tick.png') no-repeat left top;
  list-style: none;
  padding: 0 0 10px 35px;
  margin: 0;
  font-weight: 500;
}



.action-button {
  display: inline-block;
  //flex-direction: row;
  //align-items: flex-start;
  padding-left: 4px;
  padding-right: 4px;

  //position: absolute;
  //width: 10px;
  max-width: fit-content;
  //height: 24px;
  //left: 15px;
  //top: calc(50% - 24px/2 + 0.5px);
  //text-align: center;
  white-space: nowrap;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
}

.sol_success_button {
  /* Global/Danger Lighter */
  background: #ECFDF8;
  /* Global/04. Danger */
  border: 1px solid #0B6E4F;
}

.sol_primary_button {
  /* Global/Danger Lighter */
  background: #FFEED6;
  /* Global/04. Danger */
  border: 1px solid #F18D00;
}








